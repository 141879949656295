exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-become-a-partner-js": () => import("./../../../src/pages/become-a-partner.js" /* webpackChunkName: "component---src-pages-become-a-partner-js" */),
  "component---src-pages-call-booked-js": () => import("./../../../src/pages/call-booked.js" /* webpackChunkName: "component---src-pages-call-booked-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cookie-notice-js": () => import("./../../../src/pages/cookie-notice.js" /* webpackChunkName: "component---src-pages-cookie-notice-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-partner-directory-js": () => import("./../../../src/pages/partner-directory.js" /* webpackChunkName: "component---src-pages-partner-directory-js" */),
  "component---src-pages-partner-resources-certification-and-partner-status-js": () => import("./../../../src/pages/partner-resources/certification-and-partner-status.js" /* webpackChunkName: "component---src-pages-partner-resources-certification-and-partner-status-js" */),
  "component---src-pages-partner-resources-code-of-conduct-js": () => import("./../../../src/pages/partner-resources/code-of-conduct.js" /* webpackChunkName: "component---src-pages-partner-resources-code-of-conduct-js" */),
  "component---src-pages-partner-resources-index-js": () => import("./../../../src/pages/partner-resources/index.js" /* webpackChunkName: "component---src-pages-partner-resources-index-js" */),
  "component---src-pages-partner-resources-marketing-js": () => import("./../../../src/pages/partner-resources/marketing.js" /* webpackChunkName: "component---src-pages-partner-resources-marketing-js" */),
  "component---src-pages-partner-resources-partner-agreement-js": () => import("./../../../src/pages/partner-resources/partner-agreement.js" /* webpackChunkName: "component---src-pages-partner-resources-partner-agreement-js" */),
  "component---src-pages-partner-resources-plan-changes-js": () => import("./../../../src/pages/partner-resources/plan-changes.js" /* webpackChunkName: "component---src-pages-partner-resources-plan-changes-js" */),
  "component---src-pages-payroll-stats-js": () => import("./../../../src/pages/payroll-stats.js" /* webpackChunkName: "component---src-pages-payroll-stats-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-subscription-agreement-js": () => import("./../../../src/pages/subscription-agreement.js" /* webpackChunkName: "component---src-pages-subscription-agreement-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-user-agreement-changes-js": () => import("./../../../src/pages/user-agreement-changes.js" /* webpackChunkName: "component---src-pages-user-agreement-changes-js" */),
  "component---src-templates-partner-js": () => import("./../../../src/templates/partner.js" /* webpackChunkName: "component---src-templates-partner-js" */),
  "component---src-templates-release-js": () => import("./../../../src/templates/release.js" /* webpackChunkName: "component---src-templates-release-js" */)
}

